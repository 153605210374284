<template>
  <div id="modal" class="inline-block align-middle modal-bg">
    <TransitionRoot as="template" :show="true">
      <Dialog
        as="div"
        class="fixed z-40 inset-0 max-h-fill overflow-hidden md:overflow-overlay top-12 md:top-14"
        :class="`${props.class}`"
      >
        <div
          class="flex items-center justify-center min-h-full px-4 text-center sm:p-0"
        >
          <DialogOverlay
            class="fixed inset-0"
            :class="`${overlayClass}`"
            @click="hide"
          />
          <span
            class="hidden sm:inline-block sm:align-middle sm:h-fill"
            aria-hidden="true"
            >&#8203;</span
          >
          <div class="z-20 inline-block align-middle" :class="`${parentClass}`">
            <div
              id="modal-open"
              tabindex="-2"
              :class="`${containerClass} ${bgColor ?? 'bg-grayscale-7'}`"
            >
              <slot name="header"></slot>
              <slot name="main"></slot>
              <slot name="footer"></slot>
            </div>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script setup lang="ts">
import { Dialog, DialogOverlay, TransitionRoot } from "@headlessui/vue";

const props = defineProps({
  overlayClass: {
    type: String,
    default: "",
  },
  containerClass: {
    type: String,
    default: "",
  },
  class: {
    type: String,
    default: "",
  },
  parentClass: {
    type: String,
    default: "",
  },
  bgColor: {
    type: String,
    default: "",
  },
});
const emit = defineEmits(["closeModal"]);
const hide = () => {
  emit("closeModal");
};
</script>

<style scoped>
.modal-bg {
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);
}
</style>
